
const Header = () => {
  return (
    <header>
      <div className="flex justify-between items-center shadow">
        <h1 className="py-6 px-4 text-xl">message</h1>
      </div>
    </header>
  );
};

export default Header;