import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Input from './components/pages/Input';
import Target from './components/pages/Target';
import Video from './components/pages/Video';



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Input />} />
          <Route path="/target" element={<Target />} />
          <Route path="/video/:id" element={<Video />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
