import Header from '../organisms/Header';
import { BsPersonSquare, BsFillCaretRightFill } from 'react-icons/bs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Input = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [error, setError] = useState({ name: '' });

  const submit = () => {
    setError({ name: '' });

    if (name === '') {
      setError({ name: 'お名前を入力してください' });
      return;
    }

    console.log(name);
    navigate('/target');
  };

  return (
    <>
      <Header />
      <div className="container flex flex-col justify-center w-full">
        <div className='inputArea m-5'>
          <label className='flex items-center mr-5'>
            <BsFillCaretRightFill className="icon mr-1" size={10} color={'#0d9488'} />
            <span>お名前</span>
          </label>
          <div className='flex items-center mt-3'>
            <BsPersonSquare className="icon mr-1" size={30} color={'#666'} />
            <input
              id="name"
              type="text"
              placeholder="お名前"
              className="name border rounded p-2"
              onChange={ev => setName(ev.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mx-5">
          {error.name && <p className="error text-red-600">{error.name}</p>}
        </div>
        <div className="buttonArea flex items-center m-5">
          <button onClick={() => { submit() }} className='rounded py-3 px-5 bg-teal-600 text-white'>送信</button>
        </div>
      </div>
    </>
  );
};

export default Input;