import Header from "../organisms/Header";
import { Link } from "react-router-dom";
import { BsFillCaretRightFill } from 'react-icons/bs';

const Target = () => {
  const targets = [
    {
      'id': 'lX0l4vNUhoI',
      'name': '山田太郎',
      'oldage': '70',
      'image': '/images/after.webp'
    },
    {
      'id': 'bjmBJ1Fl0cs',
      'name': '山田花子',
      'oldage': '88',
      'image': '/images/w1.webp'
    },
    {
      'id': 'OguXffqBTvQ',
      'name': '山田二郎',
      'oldage': '73',
      'image': '/images/photo_sample-01_on.jpg'
    },
  ]

  const CardList = targets.map((target) => {
    return (
      <div className="card rounded shadow" key={target.id}>
        <Link to={`/video/${target.id}`}>
          <img src={target.image} alt={target.name} className="rounded w-full object-contain" />
          <div className="flex items-center">
            <BsFillCaretRightFill className="icon mr-3" size={15} color={'#0d9488'} />
            <h3 className="text-md">{target.name}</h3>
          </div>
          <p className="m-5">享年 {target.oldage}歳</p>
        </Link>
      </div>
    );
  });

  return (
    <>
      <Header />
      <div className="container flex flex-col justify-center w-full p-5">
        <h2 className="m-5">どなたのメッセージを見ますか？</h2>
        <div className="grid gap-10 grid-cols-2 md:grid-cols-4">
          {CardList}
        </div>
      </div>
    </>
  );
};

export default Target;