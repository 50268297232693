import { useParams, useNavigate } from 'react-router-dom';
import Youtube from 'react-youtube';
import Header from '../organisms/Header';
const Video = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className='m-5'>
        <button className='mb-3' onClick={() => { navigate(-1); }}>戻る</button>
        <Youtube opts={{ height: '240px', width: '320px' }} videoId={id} />
      </div>
    </div>
  );
};

export default Video;